.background-first {
    height: 150vh;
    width: 100%;
    background-image: url(../asset/images/Rectangle2.png);
    background-size: cover;
    background-color: black;
}

.background-first2 {
    height: 62vh;
    /*width: 100%;
    background-image: url(../asset/images/Rectangle3.png); */
    background-size: cover;
    background-color: black;
    background: url(../asset/images/Rectangle3.png) no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: none;
}

@media only screen and (max-width: 850px) {
    .background-first {
        height: 90vh;
        width: 100%;
        background-image: url(../asset/images/Rectangle2.png);
        background-size: cover;
        background-color: black;
        background-position: 40% 50%;
    }
}

@media only screen and (max-width: 414px) {
    .background-first {
        display: none;
    }

    .background-first2 {
        display: block;
    }
}