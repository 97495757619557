.subvaluecontent {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.largetaxtvalue {
    font-family: 'Poppins';
    font-size: 31.775px;
    color: white;
    font-weight: 700;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bluetextvalue {
    font-family: 'Poppins';
    color: #44B4CC;
    font-size: 31.775px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 100%;
}

.smalltextvalue {
    color: white;
    font-family: 'Poppins';
    font-size: 17.8734px;
    font-weight: 400;
    align-items: center;
    display: flex;
    flex-direction: column;
    letter-spacing: 1px;
    line-height: 24px;
    width: 60%;
    text-align: center;
}

@media only screen and (max-width:850px) {
    .smalltextvalue {
        width: 70%;
    }
}

@media only screen and (max-width:414px) {

    .subvaluecontent {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .largetaxtvalue {
        font-size: 16px;
    }

    .bluetextvalue {
        font-size: 16px;
    }

    .smalltextvalue {
        font-size: 10px;
        letter-spacing: 0.2px;
        width: 80%;
        line-height: 150%;
        display: flex;
        text-align: center;
    }
}