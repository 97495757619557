.div_navbar {
    padding-top: 90px;
    padding-bottom: 10px;
    padding-left: 90px;
    padding-right: 90px;
}

.button-lite {
    margin-right: 20px;
    background-image: url(../../images/ButtonDe.svg);
    width: 180px;
    height: 50px;
    background-size: 100% 100%;
}

.button-lite:hover {
    background-image: url(../../images/ButtonDeHover.svg);
    cursor: pointer;
}

.button-lite:active {
    background-image: url(../../images/ButtonDePress.svg);
}

.button-lite1 {
    margin-left: 12px;
    background-image: url(../../images/ButtonDe.svg);
    width: 170px;
    height: 50px;
    background-size: 100% 100%;
}

.button-lite1:hover {
    background-image: url(../../images/ButtonDeHover.svg);
    cursor: pointer;
}

.button-lite1:active {
    background-image: url(../../images/ButtonDePress.svg);
}

.button-launch {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11.8743px 31.6647px;
    gap: 9.9px;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    width: 180px;
    height: 43.75px;

    /* Green/Standard */
    border: 0.989521px solid #44B4CC;
    border-radius: 98.9521px;
}

.button-launch:hover {
    border: 0.989521px solid #CA61DD;
    cursor: pointer;
}

.button-launch:active {
    border: 0.989521px solid #40329D;
    position: relative;
    top: .5px;
}


.button-launch1 {
    margin-left: 13px;
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11.8743px 31.6647px;
    gap: 9.9px;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    width: 170px;

    /* Green/Standard */
    border: 0.989521px solid #44B4CC;
    border-radius: 98.9521px;
}

.button-launch1:hover {
    border: 0.989521px solid #CA61DD;
    cursor: pointer;
}

.button-launch1:active {
    border: 0.989521px solid #40329D;
    position: relative;
    top: .5px;
}

.text-color-white {
    color: #ffffff;
}

.text-z7 {
    margin-left: 10px;
    /* Z7DAO */
    height: 45.93px;
    left: 56.16px;
    right: 1048.4px;
    top: 20.75px;
    margin-top: -10px;

    font-family: 'ROBOTECHGPRegular';
    font-style: normal;
    font-weight: 400;
    /* font-size: 38.6167px; */
    font-size: 38.6px;
    line-height: 46px;
    /* identical to box height, or 119% */

    letter-spacing: 0.905078px;

    color: #FFFFFF;

}

.text-launch {
    /* Launch App */


    /* width: 100px; */
    height: 20px;

    /* Desktop/TEXT SMALL BOLD */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 0.75px;

    /* Text/Disable */

    /* color: #A0A0A0; */
    color: #ffffff;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.text-vote {
    /* Vote */
    height: 15px;
    /* Desktop/TEXT SMALL */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    letter-spacing: 0.75px;

    /* Text/Disable */

    color: #A0A0A0;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 10px;

}

.text-size-coming {

    height: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10.4px;
    line-height: 19px;
    /* identical to box height, or 184% */

    letter-spacing: 0.718389px;

    /* Text/Disable */

    color: #A0A0A0;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.text-purchase {
    /* Purchase */
    cursor: pointer;
    margin-right: 20px;
    width: 80px;
    height: 20px;

    /* Desktop/TEXT SMALL */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 0.75px;

    /* Text/Defult */

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.text-purchase1 {
    /* Purchase */
    cursor: pointer;
    margin-right: 20px;
    width: 80px;
    height: 20px;

    /* Desktop/TEXT SMALL */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 0.75px;

    /* Text/Defult */

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.div-coming {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.div-vesting {
    margin-right: 20px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.container_Hamburger {
    display: none;
    cursor: pointer;
    align-self: center;
    justify-content: flex-end;
    margin-right: 3vw;
}

.text_coming_hamburger {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 15px;
    color: #A0A0A0;
    text-align: center;
}


.items_navbar {
    width: 100%;
    text-align: center;
    margin-right: 0px;
    padding: 20px 0;

    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 5vw;

    display: block;
}

.items_navbar1 {
    width: 100%;
    text-align: center;
    margin-right: 0px;
    padding: 20px 0;
    color: #A0A0A0;
    font-family: 'Poppins', sans-serif;
    font-size: 5vw;

    display: block;
}

.div-paper {
    position: absolute;
    width: 50%;
    height: 100vh;
    z-index: 200;
    background: rgba(37, 110, 139, 0.7);
    right: 0px;
    backdrop-filter: blur(5px);
    border-bottom-left-radius: 30px;
}

@media only screen and (max-width: 850px) {
    .div_navbar {
        padding-top: 90px;
        padding-bottom: 10px;
        padding-left: 0px;
        padding-right: 0px;
    }
}


@media only screen and (max-width: 414px) {
    .div_navbar {
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .text-purchase {
        display: none;
    }

    .div-vesting {
        display: none;
    }

    .button-lite {
        display: none;
    }

    .div-coming {
        display: none;
    }

    .container_Hamburger {
        display: inline-block;
    }

    .button-launch {
        display: none;
    }

}