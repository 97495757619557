.div-first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15.8875px 0px;
    /* position: absolute; */
    height: 522.3px;
    left: 119.16px;
    right: 118.84px;
    top: 270.09px;
    margin-top: 66px;

}

.div-watch-vote-earn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;

    position: absolute;
    width: 1037px;
    /* width: 100%; */

    height: 509px;
    left: calc(50% - 1037px/2 + 0.5px);
    top: 229px;

}

.div-watch-vote-earn1 {
    display: none;
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;

    position: absolute;
    width: 1037px;
    height: 509px;
    left: calc(50% - 1037px/2 + 0.5px);
    top: 229px;

}

.group {
    width: 1037px;
    /* width: 100%; */
    height: 509px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.icon-watch {
    position: absolute;
    height: 265px;
    left: -12px;
    right: 840.93px;
    /* top: 39px; */
    top: 39px;
}

.icon-vote {
    /* position: absolute;
    height: 220px;
    left: 836.3px;
    right: 0px;
    top: 0px; */

    position: absolute;
    height: 220px;
    left: 780px;
    right: 0px;
    top: -23px;
}

.icon-earn {
    position: absolute;
    height: 213px;
    left: 804.29px;
    right: 76.51px;
    top: 296px;
}

.div-title-head {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9.92969px;
    gap: 9.93px;

    /* width: 1202px; */
    height: 379.86px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.div-sub-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9.92969px;
    gap: 9.93px;

    /* width: 1202px; */
    height: 95.86px;

    z-index: 100;
    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.text-the-first {
    /* The First Decentralized ESports Teams Platform. */
    margin-top: 30px;
    width: 784px;
    height: 360px;
    /* Desktop/ Display EXLARGE */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 1000;
    font-size: 96px;
    line-height: 90px;
    /* or 94% */
    z-index: 100;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #FFFFFF;

    /* Glow */

    text-shadow: 0px 0px 35px rgba(195, 234, 243, 0.7);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.text-the-first-sub {
    margin-top: -15px;
    /* width: 657px; */
    width: 700px;
    height: 76px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 31.775px;
    line-height: 38px;
    /* or 119% */

    text-align: center;
    letter-spacing: 0.992969px;
    text-transform: capitalize;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.imageVote {
    /* height: 273px; */
    width: 273px
}

.imageEarn {
    /* height: 273px; */
    width: 273px
}

.image-watch-png {
    width: 220px;
}

.image-vote-png {
    width: 275px;
}

.image-earn-png {
    width: 220px;
}

@media only screen and (max-width: 850px) {


    .text-the-first {
        font-size: 74px;
    }

    .icon-watch {
        width: 98.4px;
        height: 112.46px;
        left: 73.08px;
        top: 48.79px;
    }

    .icon-vote {
        height: 133.19px;
        left: 690px;
        right: 0px;
        top: -13px;
    }

    /* .imageVote {
        height: 300px;
        width: 200px
    } */

    .icon-earn {
        height: 213px;
        left: 700px;
        right: 76.51px;
        top: 286px;
    }

    .imageEarn {
        /* height: 273px; */
        width: 200px
    }

    .text-the-first-sub {
        font-size: 29px;
    }

    .group {
        width: 100%;
    }

    .div-watch-vote-earn {
        width: 100%;

    }

    .image-watch-png {
        width: 210px;
    }

    .image-vote-png {
        width: 255px;
    }

    .image-earn-png {
        width: 210px;
    }



}

@media only screen and (max-width: 414px) {
    .div-first {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15.8875px 0px;
        height: 522.3px;
        left: 119.16px;
        right: 118.84px;
        top: 270.09px;
        margin-top: 12px;

        padding: 4px 20px;

        height: 169px;
        left: 0px;
        right: 1.07px;
        top: 113px;

    }

    .div-title-head {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 12px;
        gap: 4px;

        width: 348.93px;
        height: 120px;


        /* Inside auto layout */

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }


    .text-the-first {
        width: 324.93px;
        height: 112px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 28px;
        /* or 93% */

        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;

        color: #FFFFFF;

        text-shadow: 0px 0px 12.0643px rgba(195, 234, 243, 0.7);

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 1;
    }


    .div-sub-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 12px;
        gap: 4px;

        /* width: 308.93px; */
        height: 36px;


        /* Inside auto layout */

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }


    .text-the-first-sub {
        margin-top: 15px;
        width: 324.93px;
        height: 28px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        /* or 117% */

        text-align: center;
        letter-spacing: 0.321293px;
        text-transform: capitalize;

        color: #FFFFFF;


        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 1;
    }

    .div-watch-vote-earn {
        display: none;
    }

    .div-watch-vote-earn1 {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 3.3px;

        position: absolute;
        width: 342px;
        height: 167.87px;
        left: calc(50% - 342px/2);
        top: 95px;


    }

    .group {
        width: 342px;
        height: 167.87px;


        /* Inside auto layout */

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }


    .icon-watch {
        position: absolute;
        height: 87.4px;
        left: -15px;
        top: 5px;
    }

    .icon-vote {
        position: absolute;
        height: 72.56px;
        left: 254.81px;
        right: 0px;
        top: -7.5px;

    }

    .icon-earn {
        position: absolute;
        height: 70.25px;
        left: 261px;
        right: 25.23px;
        top: 92px;

    }

    .image-watch-png {
        width: 90px;
    }

    .image-vote-png {
        width: 92px;
    }

    .image-earn-png {
        width: 80px;
    }

}