.componentValue {
    /* position: relative; */
    height: 100vh;
    width: 100%;
    background-image: url(../../asset/images/background4.png);

    background-position: center;
    background-size: cover;

    display: flex;
    justify-content: center;
}

.table {
    /* position: absolute;
    top: 10%; */
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}

.tbodyTop {
    background-color: rgb(57, 157, 178, 0.5);

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.tbody {
    background-color: rgb(57, 157, 178, 0.5);
}

.tbodyBottom {
    background-color: rgb(57, 157, 178, 0.5);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.col1 {
    display: flex;
    flex-direction: row;
    padding: 20px;
    align-items: center;
    flex: 2;
}

.col3 {
    padding: 20px;
    flex: 2;
    align-items: center;
    font-family: 'Poppins';
    font-size: 16px;
    display: flex;
    color: white;
    justify-content: center;
    /* font-weight: bold; */
}

.col2 {
    padding: 20px;
    flex: 1;
    align-items: center;
    font-family: 'Poppins';
    font-size: 16px;
    display: flex;
    color: white;
    justify-content: center;
    cursor: pointer;
    /* font-weight: bold; */
}

.col2Selected {
    padding: 20px;
    flex: 1;
    align-items: center;
    font-family: 'Poppins';
    font-size: 16px;
    display: flex;
    color: #399DB2;
    justify-content: center;
    cursor: pointer;
    /* font-weight: bold; */
}

.colText2 {
    padding: 20px;
    flex: 1;
    align-items: center;
    font-family: 'Poppins';
    font-size: 16px;
    display: flex;
    color: white;
    justify-content: center;
    font-weight: bold;
}

.titleTeamm {
    color: #399DB2;
    margin-left: 20%;
    font-weight: bold;
    font-family: 'Poppins';
    font-size: 16px;
}

.divRow {
    border-bottom-color: #399DB2;
    border-bottom-width: 1px;
    border-right-width: 0px;
    border-left-width: 0px;
    border-top-width: 0px;
    border-style: solid;

    display: flex;
    flex-direction: row;
}

.divRowBottom {
    display: flex;
    flex-direction: row;
}

.divCol {
    display: flex;
    flex-direction: row;
    /* border-bottom-color: #399DB2;
    border-bottom-width: 1px;
    border-right-width: 0px;
    border-left-width: 0px;
    border-top-width: 0px;
    border-style: solid; */
}

.divTable {
    display: block;
}

.divTableTablet {
    display: none;
}

@media only screen and (max-width: 850px) {

    /* todo */
    .divTable {
        display: none;
    }

    .divTableTablet {
        display: block;
    }

    .col3 {
        padding: 20px;
        flex: 2;
        align-items: center;
        font-family: 'Poppins';
        font-size: 16px;
        display: flex;
        color: white;
        justify-content: flex-start;
    }

    .componentValue {
        height: 80vh;

    }

}


@media only screen and (max-width: 414px) {
    .col2Selected {
        font-size: 12px;
        padding: 10px;
    }

    .col2 {
        padding: 10px;
        font-size: 12px;
    }

    .col3 {
        font-size: 12px;
    }

    .titleTeamm {
        font-size: 12px;
    }

    .colText2 {
        font-size: 12px;
    }

    .col1 {
        padding: 0px;
    }

    .table {
        margin-bottom: 50px;
    }
}