.background1 {
    height: 200vh;
    width: 100%;
    background-image: url(../../asset/images/background1.png);
    background-size: cover;
    background-color: black;

    background-repeat: no-repeat;
    background-position: 0% 30%;
}

/* .background5 {
    height: 100vh;
    width: 100%;
    background-image: url(../../asset/images/background5.png);
    background-size: cover;

} */

.background5 {
    /* height: 100vh; */
    width: 100%;
}

.background5Mobile {
    /* z-index: 1000; */
    display: none;
    height: 400px;
    margin-top: -400px;
    width: 100%;
    background-image: url(../../asset/images/background5.png);
    background-size: cover;

    background-position: 50% 50%;
}

@media only screen and (max-width:850px) {
    .background5 {
        /* height: 100vh; */
        width: 100%;
        margin-top: -120px;
    }

    .background1 {
        background-position: 50% -120%;
        height: 160vh;
    }

    .background5Mobile {
        display: none;
    }
}

@media only screen and (max-width:414px) {

    .background5 {
        background-position: 70% -70%;
        /* height: 40vh;
        width: 100%; */
        height: 42vh;
        margin-top: -88%;
        width: 150%;
        margin-left: -24%;
    }

    .background1 {
        background-position: 40% -120%;
        height: 100vh;
    }

    .background5 {
        display: none;
        /* margin-top: -500px; */
    }

    .background5Mobile {
        display: block;
    }
}