.utilitytokencontent {
    width: 100%;
    height: 100vh;
    display: flex;
}

.cardtoken {
    background-color: #282059;
    width: 45%;
    height: 85%;
    border-radius: 30px;
    margin-top: 8%;
    margin-left: 8%;
}

.cardtitle {
    font-family: 'Poppins';
    font-size: 64px;
    font-weight: 800;
    color: #C3EAF3;
    letter-spacing: 2px;
    text-align: center;
    /* margin-left: 15%; */
    margin-top: 8%;
    text-shadow: 0px 0px 35px;
}

.cardline {
    height: 2px;
    background-color: #44B4CC;
    max-width: 70%;
    margin-left: 15%;
    margin-top: 4%;
}

.cardcontent {
    font-family: 'Poppins';
    color: #C3EAF3;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 280%;
    margin-left: 8%;
    margin-right: 2%;
    margin-top: 5%;
}

.textgroup {
    line-height: 130%;
}

/* .purbutton {
    margin-top: 5%;
} */

.cardBtn{
    display: flex;
}

.purbutton {
    margin-right: 50px;
    margin-top: 5%;
    background-image: url(../../images/PurButton.svg);
    width: 40%;
    height: 100px;
    background-size: 100% 100%;
}

.purbutton:hover {
    background-image: url(../../images/PurButtonHover.svg);
    cursor: pointer;
}

.purbutton:active {
    background-image: url(../../images/PurButtonPress.svg);
}


.livebutton {
    margin-top: 5%;
    background-image: url(../../images/LiveChartDefault.svg);
    width: 40%;
    height: 100px;
    background-size: 100% 100%;
}

.livebutton:hover {
    background-image: url(../../images/LiveChartHover.svg);
    cursor: pointer;
}

.livebutton:active {
    background-image: url(../../images/LiveChartPress.svg);
}

.tokenarea {
    width: 50%;
    height: 100%;
}

.token1 {
    width: 45%;
    margin-left: 40%;
    margin-top: 7%;
}

.token2 {
    width: 30%;
    margin-top: -15%;
    margin-left: 5%;

}

.token3 {
    width: 25%;
    margin-top: 3%;
    margin-left: 40%;
    display: flex;
}

.token1m {
    display: none;
}

.token2m {
    display: none;
}

.token3m {
    display: none;
}

.tokentablet1 {
    display: none;
}

.tokentablet2 {
    display: none;
}

.tokentablet3 {
    display: none;
}



@media only screen and (max-width: 850px) {

    .utilitytokencontent {
        height: 80vh;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .cardtitle {
        font-size: 30px;
        margin-top: 5%;
        justify-content: flex-start;
        text-align: start;
        margin-left: 5%;
        text-shadow: 0px 0px 10px;
    }

    .cardline {
        margin-top: 2%;
        height: 1px;
        max-width: 88%;
        margin-left: 5%;
    }

    .cardcontent {
        font-size: 18px;
        letter-spacing: 0.2px;
        line-height: 250%;
        margin-right: 1%;
        margin-left: 5%;
    }

    .cardtoken {
        width: 80%;
        height: 55%;
        margin-left: 0;
        flex-direction: column;
        display: flex;
        border-radius: 4%;
    }

    .purbutton {
        width: 32%;
        margin-top: 0%;
    }

    .livebutton{
        width: 32%;
        margin-top: 0%;
    }


    .tokenarea {
        display: none;
    }

    .token1m {
        display: none;
    }

    .token2m {
        display: none;
    }

    .token3m {
        display: none;
    }

    .tokentablet1 {
        display: flex;
        height: 23%;
        width: 22%;
        margin-top: -27%;
        margin-right: -60%;
    }

    .tokentablet2 {
        display: flex;
        height: 15%;
        width: 17%;
        margin-top: -6%;
        margin-right: -15%;
    }

    .tokentablet3 {
        display: flex;
        height: 18%;
        width: 13%;
        margin-top: -8%;
        margin-right: -48%;
    }

}

@media only screen and (max-width:414px) {

    .utilitytokencontent {
        height: 50vh;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .cardtitle {
        font-size: 25px;
        margin-top: 5%;
        justify-content: flex-start;
        text-align: start;
        margin-left: 7%;
        text-shadow: 0px 0px 10px;
    }

    .cardline {
        margin-top: 2%;
        height: 1px;
        max-width: 80%;
        margin-left: 5%;
    }

    .cardcontent {
        font-size: 11px;
        letter-spacing: 0.2px;
        line-height: 250%;
        margin-right: 2%;
        margin-left: 6%;
    }

    .cardtoken {
        width: 65%;
        height: 70%;
        margin-left: 0;
        flex-direction: column;
        display: flex;
        border-radius: 5%;
    }

    .purbutton {
        /* width: 44%;
        margin-top: -4%; */
        margin-right: 20px
    }

    .livebutton{
        width: 32%;
        margin-top: 0%;
    }


    .tokenarea {
        display: none;
    }

    .token1m {
        display: flex;
        height: 16%;
        width: 16%;
        margin-top: -15%;
        margin-right: -52%;
    }

    .token2m {
        display: flex;
        height: 10%;
        width: 10%;
        margin-top: -4%;
        margin-right: -9%;
    }

    .token3m {
        display: flex;
        height: 9%;
        width: 10%;
        margin-top: -8%;
        margin-right: -35%;
    }

    .tokentablet1 {
        display: none;
    }

    .tokentablet2 {
        display: none;
    }

    .tokentablet3 {
        display: none;
    }

}