.contentwcam {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -600px;
}

#textwcam {
    position: relative;
    display: flex;
    color: #44B4CC;
    font-family: 'Poppins';
    font-size: 48px;
    font-weight: 750;
    letter-spacing: 0.1rem;
    justify-content: center;
    align-items: center;
    line-height: 120%;
}

#text2 {
    font-family: prompt;
    font-style: normal;
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #C3EAF3;
}

.text2box {
    margin-top: 10px;
}

@media only screen and (max-width:850px) {
    .contentwcam {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: -350px;
    }

    #textwcam {
        position: relative;
        display: flex;
        color: #44B4CC;
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.3px;
        justify-content: center;
        align-items: center;
        line-height: 150%;
    }

    #text2 {
        font-family: prompt;
        font-style: normal;
        font-weight: 600;
        font-family: 'Poppins';
        font-size: 15px;
        line-height: 150%;
        text-align: center;
        color: #C3EAF3;
    }
}

@media only screen and (max-width:414px) {

    .contentwcam {
        width: 100%;
        height: 15vh;
        display: flex;
        justify-content: center;
        margin-top: -240px;
    }

    #textwcam {
        position: relative;
        display: flex;
        color: #44B4CC;
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.3px;
        justify-content: center;
        align-items: center;
        line-height: 150%;
    }

    #text2 {
        font-family: prompt;
        font-style: normal;
        font-weight: 600;
        font-family: 'Poppins';
        font-size: 10px;
        line-height: 150%;
        text-align: center;
        color: #C3EAF3;
    }
}