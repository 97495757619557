.parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 28px;
    grid-row-gap: 28px;
}

.frame-feature {
    /* Frame 37 */


    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 48px;
    gap: 12px;

    width: 228px;
    height: 144px;

    /* Gradient/Accent green */

    background: linear-gradient(110.59deg, rgba(186, 242, 181, 0.2) 1.98%, rgba(38, 170, 212, 0.2) 95.07%);
    /* Green/ Light */

    border: 1px solid #C3EAF3;
    /* Glow */

    box-shadow: 0px 0px 35px rgba(195, 234, 243, 0.7);
    border-radius: 24px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.text-feature {
    /* Challenge */

    margin-top: 22px;
    margin-bottom: 22px;
    width: 228px;
    height: 24px;

    /* Desktop/TEXT MEDIUM BOLD */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    text-align: center;
    letter-spacing: 0.75px;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.icon-feature{
    width: 80px;
    height: 81px;
}

@media only screen and (max-width: 850px) {

    .parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 28px;
    }

    .frame-feature {
    /* Frame 37 */


    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 48px;
    gap: 12px;

    width: 60px;
    height: 50px;

    /* Gradient/Accent green */

    background: linear-gradient(110.59deg, rgba(186, 242, 181, 0.2) 1.98%, rgba(38, 170, 212, 0.2) 95.07%);
    /* Green/ Light */

    border: 1px solid #C3EAF3;
    /* Glow */

    box-shadow: 0px 0px 35px rgba(195, 234, 243, 0.7);
    border-radius: 15px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.text-feature {
    /* Challenge */

    margin-top: 10px;
    margin-bottom: 22px;
    width: 110px;
    height: 3px;

    /* Desktop/TEXT MEDIUM BOLD */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 24px;
    /* identical to box height, or 133% */

    text-align: center;
    letter-spacing: 0.75px;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.icon-feature{
    width: 30px;
    height: 31px;
}   
}

@media only screen and (max-width: 414px) {

    .parent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 11px;
        grid-row-gap: 20px;
    }

    .frame-feature {
        /* Frame 37 */
    
    
        box-sizing: border-box;
    
        /* Auto layout */
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 23px 62px;
        gap: 12px;
    
        width: 30px;
        height: 30px;
    
        /* Gradient/Accent green */
    
        background: linear-gradient(110.59deg, rgba(186, 242, 181, 0.2) 1.98%, rgba(38, 170, 212, 0.2) 95.07%);
        /* Green/ Light */
    
        border: 1px solid #C3EAF3;
        /* Glow */
    
        box-shadow: 0px 0px 35px rgba(195, 234, 243, 0.7);
        border-radius: 15px;
    
        /* Inside auto layout */
    
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }
    
    .text-feature {
        /* Challenge */
    
        margin-top: -4px;
        margin-bottom: 21px;
        width: 110px;
        height: 4px;
    
        /* Desktop/TEXT MEDIUM BOLD */
    
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 8px;
        line-height: 24px;
        /* identical to box height, or 133% */
    
        text-align: center;
        letter-spacing: 0.75px;
    
        color: #FFFFFF;
    
    
        /* Inside auto layout */
    
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }

    .icon-feature{
        width: 25px;
        height: 26px;
    }   
}