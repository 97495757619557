.background-feature {
    height: 100vh;
    width: 100%;
    background-image: url(../asset/images/BG.png);
    background-size: cover;
    /* background-color: black; */
    /* margin-top: -45px; */
    margin-bottom: -150px;
}

.container-feature {
    position: relative;
    text-align: center;
    color: white;
    z-index: 10;
    top: 200px
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 850px) {

    .background-feature {
        height: 50vh;
        width: 100%;
        background-image: url(../asset/images/BG.png);
        background-size: contain, cover;
        background-repeat: no-repeat;
        /* background-color: black; */
        /* margin-top: -45px; */
        margin-bottom: -70px;
    }

    .centered {
        position: absolute;
        top: 32%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .container-feature {
        position: relative;
        text-align: center;
        color: white;
        z-index: 10;
        top: 240px
    }

}

@media only screen and (max-width: 414px) {

    .background-feature {
        height: 100vh;
        width: 100%;
        background-image: url(../asset/images/BG_Mobile.png);
        background-size: contain, cover;
        background-repeat: no-repeat;
        /* background-color: black; */
        margin-top: 0px;
        margin-bottom: -80px;
    }

    .centered {
        position: absolute;
        top: 27%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .container-feature {
        position: relative;
        text-align: center;
        color: white;
        z-index: 10;
        top: 70px
    }

}