.gamercontent {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
}

.topic {
    color: #44B4CC;
    font-family: 'Poppins';
    font-size: 63.3293px;
    /* font-size: 3rem; */
    font-weight: 900;
    line-height: 79px;
    align-items: center;
}

.plus {
    margin-top: 2%;
    font-family: 'Poppins';
    font-size: 31.66px;
    font-weight: 800;
    color: #44B4CC;
}

.user {
    width: 10%;
}

.textgaming {
    color: white;
    justify-content: center;
    display: flex;
    font-family: 'Poppins';
    line-height: 20px;
    font-size: 13.85px;
    letter-spacing: 0.1rem;
}

.textgamer {
    color: white;
    justify-content: center;
    display: flex;
    font-family: 'Poppins';
    line-height: 20px;
    font-size: 13.85px;
    letter-spacing: 0.1rem;
}



.gamingcontent {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    width: 100%;
}

.lagertext {
    flex-direction: column;
    padding-right: 40%;
    line-height: 38px;
}

.smalltext {
    flex-direction: column;
    padding-right: 40%;
    margin-top: 2%;
}

#text {
    display: flex;
    justify-content: flex-end;
    color: white;
    font-family: 'Poppins';
    font-size: 31.77px;
}

#small2 {
    display: flex;
    justify-content: flex-end;
    color: white;
    font-size: 15.8875px;
    line-height: 20px;
    font-family: 'Poppins';
}

.gaming {
    display: flex;
    justify-content: center;
    /* background-color: forestgreen; */
    height: 50%;
}



.paragraph2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    position: absolute;
    height: 176px;
    left: 24px;
    right: 24px;
    top: 327px;
    display: none;
}

.paragraph3 {
    /* display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;

    padding: 0px;
    gap: 8px;

    position: absolute;
    height: 176px;
    /* left: 24px;
    right: 24px; */
    /* top: 327px; */
    display: none;
    width: 100%;
}

.paragraph2-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 167px;
    height: 176px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}

.paragraph2-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
    gap: 3.17px;

    width: 167px;
    height: 114px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 1;
}

.paragraph3-left {
    /* width: 70%; */
}

.paragraph3-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 3.17px;

    /* width: 167px; */
    height: 114px;


    /* Inside auto layout */

    flex: none;
    /* order: 1; */
    flex-grow: 1;
}

.text-left-sub-title {
    width: 167px;
    height: 120px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* or 111% */

    letter-spacing: 0.523348px;
    text-transform: capitalize;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.text-left-sub-title-tablet {
    text-align: end;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* or 111% */

    letter-spacing: 0.523348px;
    text-transform: capitalize;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.text-left-sub-title-tablet2 {
    text-align: end;
    margin-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    letter-spacing: 0.392511px;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.text-left-sub-title2 {
    width: 167px;
    height: 48px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    letter-spacing: 0.392511px;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}


.div-game-paragraph2 {

    width: 130px;
    height: 55.42px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}

.topic2 {
    font-family: 'Poppins';
    font-size: 35.8409px;
    font-weight: 900;
    line-height: 79px;
    align-items: center;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 35.8409px;
    line-height: 45px;
    /* identical to box height, or 125% */

    letter-spacing: 0.560013px;
    text-transform: capitalize;

    /* Green/Standard */

    color: #44B4CC;
}

.plus2 {
    margin-top: 4%;
    font-family: 'Poppins';
    font-size: 26px;
    font-weight: 800;
    color: #44B4CC;
}

@media only screen and (max-width: 850px) {
    .div-sub-title-none {
        display: none;
    }

    .paragraph2 {
        display: none;
    }

    .paragraph3 {
        display: grid;
    }

    .div-game-paragraph2 {
        width: 100%;
    }

    .textgamer {
        font-size: 10px;
    }


}

@media only screen and (max-width: 414px) {
    .div-sub-title-none {
        display: none;
    }

    .paragraph3 {
        display: none;
    }

    .textgamer {
        color: white;
        justify-content: center;
        display: flex;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 7.84019px;
        line-height: 11px;
        /* identical to box height, or 143% */

        letter-spacing: 0.42001px;

        color: #FFFFFF;
    }

    .paragraph2 {
        display: flex;
    }
}